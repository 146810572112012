// Should work for Status / AssortmentType
import type {
    SalesTransactionType,
    SalesTransactionInvoiceStatus,
    ProductAvailabilityStatus,
    ProductAssortmentType
} from '~/types';


export function useGetStatusColor(
    status: Nilish<
        string |
        SalesTransactionType |
        SalesTransactionInvoiceStatus |
        ProductAvailabilityStatus |
        ProductAssortmentType |
        unknown
    >
): string {
    let color = 'accent';

    status = String(status).toLowerCase();

    switch (status) {
        // Pre-Sale, Not Yet Available
        case 'quote':
        case 'pending':
        case 'coming_soon':
            // purple
            color = '#9c27b0';
            break;

        case 'booking':
            // indigo
            color = '#4B0082';
            break;

        // Pending Actions or Delays
        case 'updating':
        case 'on_hold':
        case 'awaiting_production':
        case 'open':
            // amber
            color = '#ffc107';
            break;

        // Backorder
        case 'sold_out':
        case 'backorder':
            // orange
            color = '#ff9800';
            break;

        // Near Ready to Dispatch
        case 'payment_processing':
        case 'assembly':
        case 'in_assembly':
        case 'in_production':
        case 'shipping_soon':
        case 'ships_soon':
        case 'processing':
            // green-lighten-1
            color = '#66BB6A';
            break;

        // Ready to Dispatch
        case 'available':
        case 'new':
        case 'in_stock':
        case 'released':
        case 'ready_to_ship':
        case 'ready_for_pickup':
        case 'production_complete':
        case 'paid':
        case 'approved':
            // green
            color = '#4CAF50';
            break;

        // Dispatched and Movement
        case 'preorder':
        case 'carryover':
        case 'posted':
        case 'shipped':
        case 'picked_up':
            // blue
            color = '#2196F3';
            break;

        // Negative Outcomes
        case 'out_of_stock':
        case 'void':
        case 'canceled':
        case 'past_due':
        case 'denied':
            // red
            color = '#F44336';
            break;

        // Neutral
        case 'draft':
        case 'not_submitted':
            // grey-lighten-1
            color = '#bdbdbd';
            break;

        // Completed
        case 'completed':
        case 'fulfilled':
        case 'delivered':
            // purple
            color = '#9c27b0';
            break;

        // Other
        case 'unavailable':
            // blue-grey
            color = '#7393B3';
            break;

        // Default color
        default:
            // grey-darken-2
            color = '#616161';
    }

    return `${color}`;
}
